<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Applicant Details
        </h3>
      </v-col>

      <v-col cols="12">
        <v-img
          :src="`/uploads/${item.application_year}/${item.passport}`"
          height="150"
          width="150"
          class="mx-auto"
          contain
        >
          <template #placeholder>
            <v-skeleton-loader
              v-bind="attrs"
              type="image"
            ></v-skeleton-loader>
          </template>
        </v-img>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Email: </span>
        {{ item.email }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Date of Birth: </span>
        {{ item.dob }} : {{ new Date().getFullYear() - new Date(item.dob).getFullYear()}} yrs
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Marital Status: </span>
        {{ item.marital_status }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="item.marital_status == 'Married'">
        <span class="font-weight-bold">Married, and will come to stay for two years: </span>
        {{ item.married_stay }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">State of Origin: </span>
        {{ item.ostate }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Resident State: </span> {{ item.rstate }}
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Resident Country: </span>
        {{ item.rcountry }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Health Challenges: </span>
        {{ item.health_challenges }}
      </v-col>
       <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Previous Education: </span>
        {{ item.previous_education }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Qualification: </span>
        {{ item.qualification }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Discipline: </span>
        {{ item.discipline }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Certificate: </span>
        <v-btn
          v-if="!item.awaiting_result"
            small
            color="primary"
            :href="`/uploads/${item.application_year}/${item.certificate}`"
            target="_blank"
            >View Certificate</v-btn>
        <span v-else>Still Awaiting Result</span>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Application Year: </span>
        {{ item.application_year }}
      </v-col>

      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Spiritual Details
        </h3>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Born Again: </span>
        {{ item.born_again }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="item.born_again == 'Yes'">
        <span class="font-weight-bold">Salvation Experience: </span>
        {{ item.salvation_experience }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">In Discipleship: </span>
        {{ item.in_discipleship }}
      </v-col>
      <v-col cols="12">
      <v-row v-if="item.in_discipleship == 'Yes'">
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Discipler Name: </span>
        {{ item.discipler_name }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Discipler Address: </span>
        {{ item.discipler_address }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Discipler Mobile No.: </span>
        {{ item.discipler_phoneno }}
      </v-col>
      </v-row>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Church Attented: </span>
        {{ item.church }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Mission Burden: </span>
        {{ item.mission_burdened }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" v-if="item.mission_burdened == 'Yes'">
        <span class="font-weight-bold">Mission burden details: </span>
        {{ item.mission_burden_details }}
      </v-col>
     

      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Parent Details
        </h3>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Parent Name: </span>
        {{ item.parent_name }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Parent Address: </span>
        {{ item.parent_address }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Parent Phone.No: </span>
        {{ item.parent_phoneno }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Parent Email: </span>
        {{ item.parent_email }}
      </v-col>

      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Referee Details
        </h3>
      </v-col>
    </v-row>
    <v-row v-for="(ref, idx) in item.referees" :key="ref.id" justify="center">
      <v-col cols="12" class="text-center">
        <b class="primary--text">Referee {{ idx + 1 }}: </b>
        <b>Reference Submitted: </b>
        {{ ref.submitted ? "Yes" : "No" }}
        <v-btn v-if="!ref.submitted" x-small :loading="loading" color="primary" @click="resendMail(item, ref)" class="px-2"> Resend mail</v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Name: </span>
        {{ ref.referee_name }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Email: </span>
        {{ ref.referee_email }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Phone Number: </span>
        {{ ref.referee_phoneno }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Referee Address: </span>
        {{ ref.referee_address }}
      </v-col>
      <v-col cols="12">
  <v-row v-if="ref.submitted">
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Reference Applicant fullname: </span>
        {{ ref.applicant_fullname }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Time length you have known applicant: </span>
        {{ ref.time_length }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">In what capacity do you know the applicant: </span>
        {{ ref.capacity}}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Salvation experience: </span>
        {{ ref.salvation_experience }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant walk with God: </span>
        {{ ref.walk_with_God }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant Strength: </span>
        {{ ref.strength }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Applicant weakness or area for development: </span>
        {{ ref.weaknesses }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Exceptional ability or performance: </span>
        {{ ref.exceptional_ability }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Ability to work independently or as team member: </span>
        {{ ref.work_ability }}
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <span class="font-weight-bold">Comment on other areas: </span>
        {{ ref.other_areas }}
      </v-col>
    </v-row> 
    </v-col>
    </v-row>
     <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";

export default {
  name: "StudentDetails",
  mixins: [snackMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
    }
  },
  methods: {
    resendMail(applicant, referee) {
      this.loading = true;
      let data = {applicant: {...applicant, referees:[]}, referee}
      apiClient
        .post("/mail_referee.php", data)
        .then((res) => {
          if (+res.data.mailsent) {
            this.displayMsg("Mail sent successfully")
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) =>this.displayMsg(err.message, "error"));
    },
    }
  }
</script>
