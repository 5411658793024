<template>
  <div class="green lighten-3">
    <v-container fluid >
    <v-row justify="center" class="my-3">
      <v-btn
        dense
        x-small
        v-for="admin in admins"
        :key="admin"
        :disabled="search==admin"
        class="mx-1 mb-2 px-2"
        @click="search = admin"
      >
        {{ admin }}
      </v-btn>
      </v-row>
      <v-row justify="center" class="my-3" v-if="search == 'referenced'">
          <v-switch label="both referees" value="both" v-model="ref"></v-switch>
        <v-switch label="only one" value="one" v-model="ref"></v-switch>
        <v-switch label="at least one" value="atleastone" v-model="ref"></v-switch>
        <v-switch label="none" value="none" v-model="ref"></v-switch>
      </v-row>
    <v-data-table
      :headers="headers"
      :items="applicants"
      show-expand
      class="elevation-1"
      :search="search"
      :custom-filter="filterApplicants"
    >
      <template v-slot:top="{pagination: {itemsLength}}">
        <v-row justify="end" no-gutters>
          <v-col cols="12" class="mb-0">
            <v-toolbar flat>
              <v-toolbar-title>Total Applicants : <b class="success--text">{{ itemsLength }}</b></v-toolbar-title>
            </v-toolbar>
          </v-col>

          <v-col cols="12" md="6" class="mb-5 px-3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.dob="{ value }">
        {{ value }} : {{ new Date().getFullYear() - new Date(value).getFullYear()}} yrs
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          x-small
          class="mr-2"
          :to="{ name: 'Application', params: { editUser: item } }"
          title="Click to edit this applicant"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          @click="delRecord(item)"
          title="Click to delete this applicant"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="my-6">
        <student-details :item="item" />
            
            <v-row no-gutters>
              <v-col v-for="action in actions" :key="`action-${action}`" cols="auto" class="my-0 py-0 mx-2">
                <v-switch 
                v-model="item[action]" 
                :label="action"
                :false-value="0"
                :true-value="1"
                @change="saveChanges(item, action)"></v-switch>
              </v-col>              
            </v-row>
          </div>
        </td>
      </template>
    </v-data-table>
    <vue-excel-xlsx
        class="text-center mt-2"
        :data="applicants"
        :columns="excelHeaders"
        file-name="phati-applicants"
        :file-type="'xlsx'"
        sheet-name="applicants"
        >
        <v-btn small rounded class="white--text" color="primary">
        Export App. to Excel</v-btn
      >
    </vue-excel-xlsx> 
    &nbsp;
    <vue-excel-xlsx
        class="text-center mt-2"
        :data="shortlistedReferences"
        :columns="excelHeadersRef"
        file-name="shortlisted-references"
        :file-type="'xlsx'"
        sheet-name="references"
        >
        <v-btn small rounded class="white--text" color="primary">
        Export Ref. to Excel</v-btn
      >
    </vue-excel-xlsx>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import StudentDetails from "@/components/Admin/StudentDetails";
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
export default {
  name: "ApplicantInfo",
  mixins: [snackMixin],
  components: {
    StudentDetails
  },
  data() {
    return {
      expanded: [],
      search: "",
      ref: 'both',
      headers: [
        { text: "Application No", value: "application_no" },
        { text: "Surname", value: "surname" },
        { text: "Other Name", value: "other_names" },
        { text: "Gender", value: "gender" },
        { text: "PhoneNumber", value: "phoneno" },
        { text: "Age", value: "dob" },
        { text: "Marital Status", value: "marital_status" },
        { text: "Residential State", value: "rstate" },
        { text: "Application Type", value: "application_type" },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" }
      ],
      excelHeaders: [
        { label: "Application No", field: "application_no" },
        { label: "Surname", field: "surname" },
        { label: "Other Names", field: "other_names" },
        { label: "Phone No", field: "phoneno" },
        { label: "Email ", field: "email" },
        { label: "Sex", field: "gender" },        
        { label: "Age(Yrs)", field: "dob", dataFormat:this.formatAge },
        { label: "Origin State", field: "ostate" },
        { label: "Resident State", field: "rstate" },
        { label: "Marital Status", field: "marital_status" },
        { label: "Qualification", field: "qualification" }, 
        { label: "Application Type", field: "application_type" }, 
        { label: "Ref", field: "referees", dataFormat:this.formatRef },
        {label: "Shortlisted", field: "shortlisted"},
        { label: "Interviewed", field: "interviewed"},       
        { label: "Admitted", field: "admitted"},
        { label: "Date Created", field: "created_on"},       
      ],
      excelHeadersRef: [
        { label: "Application No", field: "application_no" },
        { label: "Surname", field: "surname" },
        {label: "Other Names", field: "other_names"},
        {label: "Ref Applicant Name", field: "applicant_fullname"},
        { label: "Referee Name", field: "referee_name"},       
        { label: "Referee Email", field: "referee_email"},       
        { label: "Ref Phone No", field: "referee_phoneno"},       
        { label: "Referee Address", field: "referee_address"},       
        { label: "Time Length", field: "time_length"},       
        { label: "Applicant Capacity", field: "capacity"},       
        { label: "Salvation Experience", field: "salvation_experience"},       
        { label: "Applicant Walk With God", field: "walk_with_God"},       
        { label: "Applicant Strength", field: "strength"},       
        { label: "Applicant Weakness", field: "weaknesses"},       
        { label: "Exceptional Ability", field: "exceptional_ability"},       
        { label: "Ability to Work Independently", field: "work_ability"},       
        { label: "Other Comment", field: "other_areas"},       
        { label: "Shortlisted", field: "shortlisted"},       
        { label: "Interviewed", field: "interviewed"},       
        { label: "Admitted", field: "admitted"},      
      ],
      /*
        referee_name, referee_email, referee_phoneno, referee_address, time_length, capacity, salvation_experience, walk_with_God, strength, weaknesses, exceptional_ability, work_ability, other_areas 
      */
  admins: ["unsubmitted", "submitted", "referenced", "unshortlisted", "shortlisted", "interviewed", "admitted", "resumed", "interns"],
  actions: ["shortlisted", "interviewed", "admitted", "resumed"],
      applicants: [],
    };
  },
  computed: {
    shortlistedApplicants(){
      return this.applicants.filter( applicant => !!applicant.shortlisted );
    },
    shortlistedReferences () { 
      const references = [] 
      this.shortlistedApplicants.forEach( ap => { 
        const {application_no, surname, other_names, referees, shortlisted, interviewed, admitted} = ap;
        const validRef = Array.isArray( referees ) && referees.length > 1;
        if ( validRef ) {
          if ( referees[ 0 ].submitted ) {
            let ref1 = {application_no, surname, other_names, shortlisted, interviewed, admitted, ...referees[ 0 ]}
            references.push(ref1)
          }
          if ( referees[ 1 ].submitted ) {
            let ref2 = {application_no, surname, other_names, shortlisted, interviewed, admitted, ...referees[ 1 ]}
            references.push(ref2)
          }
        }
      });    
      
      return references;
    } 
  },
  async created(){
    this.getRecords()
  },
  methods: {
    filterApplicants(value, search, item) {
      const validRef = item.submitted && Array.isArray(item.referees) && item.referees.length > 1;
      switch (search) {
        case "unsubmitted":
          return !item.submitted;
        case "submitted":
          return !!item.submitted;
        case 'unshortlisted':
          return !!item.submitted && !item.shortlisted;
        case 'shortlisted':
          return !!item.shortlisted;
        case 'interviewed':
          return !!item.interviewed;
        case 'admitted':
          return !!item.admitted;
        case 'resumed':
          return !!item.resumed;
        case 'interns':
          return item.application_type == 'intern';
        case 'referenced':          
          return validRef && this.ref == 'both' ? !!item.referees[0].submitted && !!item.referees[1].submitted : validRef && this.ref == 'one'? (!!item.referees[0].submitted && !item.referees[1].submitted) || (!item.referees[0].submitted && !!item.referees[1].submitted) : validRef && this.ref == 'atleastone'? !!item.referees[0].submitted || !!item.referees[1].submitted : validRef && this.ref == 'none'? !item.referees[0].submitted && !item.referees[1].submitted : false;
        default:
          return (
            value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
      }
    },
    async getRecords() {
      this.loading = true;
      try {
        const res = await apiClient.get("/applicants.php?u=2");
        if (Array.isArray(res.data)) {
          this.applicants = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err.message, "error");
      }
      this.loading = false;
    },

    async saveChanges(item, type) {
      this.loading = true;
      item.type = type;
      try {
        const res = await apiClient.put("/applicants.php", item);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err.message, "error");
      }
    },

    delRecord(record) {
      const ok = confirm(
        `Are sure you want to delete ${record.surname} ${record.other_names}?`
      );
      if (ok) {
        this.displayMsg(`Deleting ${record.surname} ${record.other_names}, please wait...`);
        apiClient
          .delete(`/applicants.php?${record.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${record.surname} ${record.other_names} not successfully deleted`,
                "error"
              );
              return;
            }
            this.applicants = this.applicants.filter((rec) => rec.id !== record.id);
            this.displayMsg(`${record.surname} ${record.other_names} successfully deleted`);
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },
    formatAge(value){
      return new Date().getFullYear() - new Date(value).getFullYear();
    },
    formatRef(value){
      const validRef = Array.isArray(value) && value.length > 1;
      return validRef ? value[0].submitted + value[1].submitted : 0;
    },
  }
};
</script>
